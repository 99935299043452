<template>
  <!-- <div class="popup_overlay px-4">
    <div
      class="custom_dialog rounded-xl"
      id=""
      style="width: 600px; max-height: 85%; overflow: auto"
    >
      <div class="relative bg-white">
        <div class="bg-primary flex justify-between p-3 sticky top-0">
          <div class="font-medium text-white heading-3">Add New Tool</div>
        </div>
        <div
          class=""
        > -->
          <div style="height: 300px" class="overflow-auto">
            <div class="p-4">
              <div class="mb-4">
                <TextField
                  :inputId="'34324testInput'"
                  :textMaxlength="50"
                  :showcharLimit="true"
                  :inputext="toolName"
                  :placholderText="`Tool Name`"
                  :lableText="'Tool Name'"
                  :autoFocus="true"
                  @inputChangeAction="(data) => (toolName = data)"
                />
              </div>
              <div class="mb-4">
                <TextAreaInput
                  :inputId="'435testInput'"
                  :inputext="toolDescription"
                  :placholderText="`Write Description here...`"
                  :lableText="'Tool Description'"
                  :inputType="'text'"
                  :autoFocus="false"
                  :textMaxlength="1000"
                  :showcharLimit="true"
                  :cols="50"
                  :rows="3"
                  :setReadOnly="false"
                  @inputChangeAction="(data) => (toolDescription = data)"
                />
              </div>
            </div>
            <div
              class="absolute -bottom-1 bg-white w-full flex justify-end pr-3 pt-3"
            >
              <div class="text-rigth flex gap-2 mb-3">
                <Button
                  :btnSize="'medium'"
                  :textColor="'text1'"
                  :btnColor="'gray1'"
                  :btnText="'Cancel'"
                  @buttonAction="cancelAddTool"
                />
                <Button
                  :disabled="toolName === ''"
                  :btnSize="'medium'"
                  :textColor="'white'"
                  :btnColor="'success'"
                  :btnText="'Add'"
                  @buttonAction="AddNewCall()"
                />
              </div>
            </div>
          </div>
        <!-- </div>
      </div>
    </div>
  </div> -->
</template>
<script>
import Button from "@/View/components/globalButton.vue";
import TextField from "@/View/components/textfield.vue";
import TextAreaInput from "@/View/components/textAreaInput.vue";
export default {
  props: ["toolList"],
  components: {
    Button,
    TextField,
    TextAreaInput,
  },
  data() {
    return {
      toolName: "",
      toolDescription: "",
    };
  },
  created() {},
  mounted() {},
  methods: {
    discard() {
      document.body.style = "overflow: visible;";
      this.$emit("cancelSelectTool");
    },
    cancelAddTool() {
        this.$emit("handelAddNewTool", null);},
    AddNewCall() {
      if (this.toolName !== "") {
        let data = {
          toolMasterId: 0,
          toolName: this.toolName,
          toolDescription: this.toolDescription,
          displayOrder: 0,
          isActive: true,
          selected: true,
        };

        this.$emit("handelAddNewTool", data);
      }
    },
  },
  beforeDestroy() {},
};
</script>
<style scoped>
</style>