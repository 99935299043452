<template>
  <div class="popup_overlay px-4">
    <div
      class="custom_dialog rounded-xl"
      id="itemdialogs"
      style="width: 600px; max-height: 85%; overflow: auto"
    >
      <div class="relative bg-white">
        <div class="bg-primary flex justify-between p-3 sticky top-0">
          <div v-if="!isAddNew" class="font-medium text-white heading-3">Select Tool</div>
          <div v-else class="font-medium text-white heading-3">Add New Tool</div>
          <div
            v-if="!isAddNew"
            @click="
              addNewShow
            "
            class="cursor-pointer font-medium text-white heading-3"
          >
            + Add New
          </div>
        </div>
        <!-- <div
          style="overflow-y: auto; white-space: nowrap"
          class=""
        > -->
        <div   v-if="!isAddNew" style="white-space: nowrap" class="mb-4">
            <div
            v-for="(data, index) in toolMList"
            :key="`tool-${index}`"
            class="cust_card m-2 heading-3 border-b border-gray1 bg-white text-sm px-3 py-3 items-center hover:bg-gray1 cursor-pointer"
            @click="data.selected = !data.selected"
          >
            <div class="flex items-center justify-between">
              <p class="text-gray4 heading-5" style="font-weight: bold">
                {{ data.toolName }}
              </p>
              <span v-if="data.selected"
                ><i class="fa-solid fa-check text-primary"></i
              ></span>
            </div>
          </div>
          <div
            class=" bg-white w-full flex justify-end pr-3 pt-3"
          >
            <div class="text-rigth flex gap-2 mb-3">
              <Button
                :btnSize="'medium'"
                :textColor="'text1'"
                :btnColor="'gray1'"
                :btnText="'Cancel'"
                @buttonAction="discard()"
              />
              <Button
                :btnSize="'medium'"
                :textColor="'white'"
                :btnColor="'success'"
                :btnText="'Save'"
                @buttonAction="SaveToolsDetail()"
              />
            </div>
          </div>
          </div>
          
        <AddNewTool  v-if="isAddNew" @handelAddNewTool="handelAddNewTool" />
      </div>
    </div>
    <!-- <AddNewTool v-if="isAddNew" @handelAddNewTool="handelAddNewTool" /> -->
  </div>
</template>
<script>
import Button from "@/View/components/globalButton.vue";
// import TextField from "@/View/components/textfield.vue";
// import TextAreaInput from "@/View/components/textAreaInput.vue";
import AddNewTool from './AddNewTool.vue';
export default {
  props: ["toolList"],
  components: {
    Button,
    // TextField,
    // TextAreaInput,
    AddNewTool,
  },
  data() {
    return {
      toolDescription: "",
      isAddNew: false,
      toolMList: [],
      tempList: null,
    };
  },
  created() {},
  mounted() {
     this.toolMList = [...this.toolList];
    
  },
  watch: {
    toolMList: {
        handler () {
            console.log('list --------------------------', this.toolMList);
        },deep: true
    },
    isAddNew: {
        handler () {
            console.log('list --------------isAddNew------------', this.isAddNew);
            console.log('list -------------isAddNew-------------', this.toolMList);
        },deep: true
    }
  },
  methods: {
    addNewShow() {
        console.log('list --------------------------', this.toolMList);
        this.isAddNew = true;
    },
    discard() {
      document.body.style = "overflow: visible;";
      this.$emit("selectToolHandler", null);
    },
    SaveToolsDetail() {
        let data = []
        this.toolMList.forEach(element => {
            if (element.selected) {
                data.push({
                    toolMasterId: element.toolMasterId,
                    jobVisitToolMappId: 0,
                    toolName: element.toolName,
                    toolDescription: element.toolDescription,
                    isPickedUp: false,
                    isPickedUpUser: 0,
                    pickedUpDateTime: '',
                })
            }
        });
        this.$emit('selectToolHandler', data)
    },
   handelAddNewTool(newTool) {
       this.isAddNew = false;
        if (newTool !== null) {
        this.toolMList.push(newTool);
      }
    },
  },
  beforeDestroy() {},
};
</script>
<style scoped>
</style>