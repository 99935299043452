<template>
    <div class="popup_overlay px-4">
    <div class="custom_dialog rounded-xl" style="width: 800px; overflow: auto; max-height: 85%;">
      <div class="relative bg-screen_bg">
        <div>
          <div class="bg-primary flex justify-between p-3">
            <div class=" font-medium text-white heading-3">
              <span >Set Location</span>
            </div>
          </div>
          <div class="p-4">
           <div class="flex gap-4 justify-between">
             <div v-if="userAddress !== null" @click="showOnMap(userAddress)" class=" w-full cust_card text-primary bg-white p-2 text-center cursor-pointer mb-4">
                <div class="flex justify-center text-center items-center">
                    <span class="pr-1"><i class="fa-solid fa-location-crosshairs"></i></span>
                    <span>Current Location</span>
                </div>
            </div>
             <div v-if="orgAddress !== null" @click="showOnMap(orgAddrObj)" class="w-full cust_card text-primary bg-white p-2 text-center cursor-pointer mb-4">
                <div class="flex justify-center text-center items-center">
                    <span class="pr-1"><i class="fa-solid fa-location-dot"></i></span>
                    <span>Locate on Map</span>
                </div>
            </div>
           </div>
            <div v-if="userAddress !== null || orgAddress !== null">
              OR
            </div>
            <div @click="SearchNewAddress" class="cust_card text-primary bg-white p-2 text-center cursor-pointer mb-4">
                <div class="flex justify-center text-center items-center">
                    <span>Search New Address</span>
                </div>
            </div>
            <p v-if="addJobObj.fullAddress" class="heading-5 font-semibold  text-text2">Current selected address</p>
            <div v-if="addJobObj.fullAddress" class=" mb-4">
                <div>
                    <div @click="showOnMap(addJobObj)" class="cust_card p-2 mb-2 text-text1 cursor-pointer bg-white hover:bg-gray1">
                        <div class="mb-2" >
                        </div> 
                        <div class="" >
                            {{addJobObj.fullAddress}}
                        </div> 
                    </div>
                </div>
            </div>
            <p v-if="addressList.length > 0" class="heading-5 font-semibold text-text2">Select from Saved Address in Customer File</p>
            <div class=" mb-4">
                <!-- <div v-if="addressList.length > 0">
                    <div v-for="(add, ind) in addressList" :key="ind" @click="showOnMap(add)" class="cust_card p-2 mb-2 text-text1 cursor-pointer bg-white hover:bg-gray1">
                        <div class="mb-2" >
                        <span :class="` whitespace-nowrap border border-gray2 rounded-xl  text-text1 p-1`">
                            {{add.addressTitle}}</span>
                        </div> 
                        <div class="" >
                            {{add.fullAddress}}
                        </div> 
                    </div>
                </div> -->
                <div v-for="(add,ind) in addressList" :key="ind">
                  <div @click="selectJobAddress(add, ind)" class="cursor-pointer bg-white mb-2 cust_card p-2 text-text1">
                    <div class="flex items-center justify-between">
                      <div class="flex gap-1">
                      <span class=" border border-gray2 rounded-md heading-7 px-1">
                        {{add.addressTitle === '' ? 'Other' : add.addressTitle}}
                      </span>
                      </div>
                      <p class="text-error heading-7">{{add.displayDistance}}</p>
                    </div>
                    <div>
                      <p>{{add.fullAddress}}</p>
                    </div>
                  </div>
                </div>  
            </div>
          </div>
        </div>
          <div class=" sticky bottom-0 bg-screen_bg w-full flex justify-end pr-3">
            <div class="text-rigth flex gap-2 mb-3">
                <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'white'" :btnText="'Discard'" @buttonAction="discard()"/>
            </div>
            </div>
        </div>
    </div>
    <EditAddress :jobDetail="addressObj" v-if="addressObj !== null" :showAdditional="true" />
  </div>
</template>
<script>
import Button from '@/View/components/globalButton.vue'
import deboucneMixin from '@/mixins/debounce.js'
import EditAddress from '../../components/EditAddress.vue'


/* global google */
// import GmapCircle from 'vue2-google-maps/dist/components/circle'
import * as VueGoogleMaps from 'vue2-google-maps'
import Vue from 'vue'
import store from '@/store'
Vue.use(VueGoogleMaps, {
  load: {
    key: store.getters.googleMapApiKey,
    libraries: 'places,drawing' // necessary for places input
  }
})
export default {
  name: 'ReqAddressAddUpdate',
  components: {
    Button,
    EditAddress,
  },
  mixins: [deboucneMixin],
  data () {
    return {
        selectedcustomerAddress: false,
        jobDisplayAdd: '',
        fullAddress: '',
        milsCList: [],
        addressErr: '',
        customerId: 0,
        addressObj: null,
        userAddress: null,
        orgAddress: null,
        orgAddressObj: null,
        userLat: 0,
        userLong: 0,
    }
  },
  props: ['addJobObj', 'addressList'],
   created() {
  },
  mounted () {
    console.log('checl -------------------------------------------------------------------', this.addJobObj);
    this.orgAddress = JSON.parse(localStorage.getItem('orgInfo'))

     if ("geolocation" in navigator) {
      // Request permission to access location
 
      navigator.geolocation.getCurrentPosition(
        (position) => {  // Use an arrow function here
          // Get coordinates
          if (position.coords.latitude && position.coords.longitude) {
            this.userLat = position.coords.latitude;
            this.userLong = position.coords.longitude;      
          }
        },
        (error) => {
          console.error("Error obtaining location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }

    this.$root.$on('EditAddressHandler', (response, data) => {
        console.log('check 111111');
      if (response && data !== null) {
        this.$root.$emit('createjobAddressHandler', true, data, this.selectedcustomerAddress ? false : true)
      }
      this.selectedcustomerAddress = false
      this.addressObj = null
    })
    
  },
  beforeDestroy() {
    this.$root.$off("EditAddressHandler");
  },
   watch: {
    userLat: {
      handler() {
      if (this.userLat !== 0 && this.userLong !== 0) {
        this.getAddress(this.userLat, this.userLong, 'user')
      }
      }
    },
    orgAddress: {
      handler() {
      if (this.orgAddress !== null) {
        this.getAddress(this.orgAddress.latitude, this.orgAddress.longitude, 'org')
      }
      }
    }
  },
  methods: {
        selectJobAddress(data) {
            this.selectedcustomerAddress = true
            let temp = {
                addressLine1: data.addressLine1,
                addressLine2: data.addressLine2,
                addressTitle: data.addressTitle,
                buildingName: data.buildingName,
                city: data.city,
                country: data.country,
                directionNote: data.directionNote,
                floorNumber: data.floorNumber,
                fullAddress: data.fullAddress,
                houseNo: data.houseNo ,
                latitude: data.latitude ,
                longitude: data.longitude ,
                state: data.state ,
                zip: data.zip,
            }
            this.addressObj = temp
    },
    showOnMap (data) {
        console.log("this.setAddress(data, true);this.setAddress(data, true);", data);
        this.addressObj = data
    },
    SearchNewAddress () {
      this.$emit('SearchNewAddress', true)
    },
    discard () {
      this.$emit('SetLocationCreateJobHanler')
    },
    getAddress(lat, lng, from) {
      let newAddress = ''
      var geocoder = new google.maps.Geocoder;
      var latlng = {lat: lat, lng: lng};
      let addressComponents = {};
      geocoder.geocode({'location': latlng}, function(results, status) {
        if (status === 'OK') {
          if (results[1]) {
            newAddress = results[1].formatted_address

        const components = results[1].address_components;

        components.forEach(component => {
          const types = component.types;
          if (types.includes('street_number')) {
            addressComponents.houseNo = component.long_name;
          }
          if (types.includes('route')) {
            addressComponents.addressLine1 = component.long_name;
          }
          if (types.includes('locality')) {
            addressComponents.city = component.long_name;
          }
          if (types.includes('administrative_area_level_1')) {
            addressComponents.state = component.long_name;
          }
          if (types.includes('country')) {
            addressComponents.country = component.long_name;
          }
          if (types.includes('postal_code')) {
            addressComponents.zip = component.long_name;
          }
          // Add more address component types as needed
        });

          } else {
            window.alert('No results found');
          }
        }
      })
      let center = {
        lat: latlng.lat,
        lng: latlng.lng
      }
      let markers = []
        markers.push({ position: center })
      setTimeout(() => {
          
         let finalData = {
          addressLine1: addressComponents.addressLine1 || "",
          addressLine2: addressComponents.addressLine2 || "",
          addressTitle: addressComponents.addressTitle || "",
          buildingName: addressComponents.buildingName || "",
          city: addressComponents.city || "",
          country: addressComponents.country || "",
          directionNote: addressComponents.directionNote || "",
          floorNumber: addressComponents.floorNumber || "",
          fullAddress: newAddress,
          houseNo: addressComponents.houseNo || "",
          latitude: latlng.lat,
          longitude: latlng.lng,
          state: addressComponents.state || "",
          zip: addressComponents.zip || "",
        }
        if (from === 'user') {
          this.userAddress = finalData
        } else if (from === 'org') {
          this.orgAddrObj = finalData
        }
      }, 500);
    },
  },
}
</script>
<style scoped>
</style>